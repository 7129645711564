import React from 'react';
import { graphql } from 'gatsby';

import team from 'data/team.json';
import { PostTemplate } from 'templates';
import { MarkdownPageData } from 'types';

interface Props {
	data: MarkdownPageData;
}

export default function Post({ data }: Props) {
	const { frontmatter, html } = data.markdownRemark;

	const member = team.members.find(member => member.name === frontmatter.author);
	const imageSource = frontmatter.imageSource ? frontmatter.imageSource : undefined;
	const authorImage = frontmatter.authorImage
		? frontmatter.authorImage.childImageSharp
		: undefined;

	return (
		<PostTemplate
			title={frontmatter.title}
			date={frontmatter.date}
			featuredImage={frontmatter.featuredImage}
			imageSource={imageSource}
			excerpt={frontmatter.excerpt}
			text={html}
			authorImage={authorImage}
			member={member}
		/>
	);
}

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			timeToRead
			excerpt
			frontmatter {
				title
				tags
				date
				featuredImage {
					childImageSharp {
						fluid(quality: 85, maxWidth: 3840) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				imageSource
				excerpt
				author
				authorImage {
					childImageSharp {
						fluid(quality: 85, maxWidth: 200) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				authorImage_alt
				category
			}
		}
	}
`;
