import { formatDistanceToNow, parse } from 'date-fns';
import React from 'react';

import { BlogUpdates, BlogPostPreviewList, BlogPostHero, BlogPostHeader } from 'components/Blog';
import { SEO } from 'components/UI';
import { BLOG_POST_DATE_FORMAT } from 'consts';
import { previous } from 'data/post.json';
import seo from 'data/seo.json';
import { MediaQueries } from 'environment';
import { useQueryContext, useMediaQuery } from 'hooks';
import { Member, ImageSharpNode, ImageSharpData } from 'types';

import {
	Container,
	PostContent,
	PreviousArticles,
	LeftColumn,
	MarkdownContent
} from './PostTemplate.style';

interface Props {
	title: string;
	date: string;
	imageSource?: string;
	excerpt: string;
	featuredImage?: ImageSharpData;
	netlifyFeaturedImage?: string;
	text: string;
	authorImage?: ImageSharpNode;
	netlifyAuthorImage?: string;
	member?: Member;
	isNetlifyWidget?: boolean;
}

export function PostTemplate({
	title,
	date,
	featuredImage,
	imageSource,
	netlifyFeaturedImage,
	excerpt,
	text,
	authorImage,
	netlifyAuthorImage,
	member,
	isNetlifyWidget
}: Props) {
	const { posts } = useQueryContext();
	const isPhone = useMediaQuery(MediaQueries.phone);
	const isTablet = useMediaQuery(MediaQueries.tablet);
	const isDesktop = useMediaQuery(MediaQueries.desktop);

	const formattedDate = formatDistanceToNow(parse(date, BLOG_POST_DATE_FORMAT, new Date()), {
		addSuffix: true
	});
	const ogImage = featuredImage
		? featuredImage.childImageSharp.fluid.src
		: seo.blogPostOgImageAlt;

	return (
		<Container>
			{!isNetlifyWidget && (
				<SEO
					title={title}
					description={excerpt}
					ogTitle={title}
					ogDescription={excerpt}
					ogImage={ogImage}
				/>
			)}
			<BlogPostHero
				featuredImage={featuredImage}
				netlifyImage={netlifyFeaturedImage}
				imageSource={imageSource}
				isNetlifyWidget={isNetlifyWidget}
			/>
			<PostContent>
				<LeftColumn>
					<BlogPostHeader
						title={title}
						date={formattedDate}
						member={member}
						authorImage={authorImage}
						netlifyAuthorImage={netlifyAuthorImage}
						isNetlifyWidget={isNetlifyWidget}
					/>
					{isNetlifyWidget ? (
						<MarkdownContent>{text}</MarkdownContent>
					) : (
						<MarkdownContent dangerouslySetInnerHTML={{ __html: text }} />
					)}
				</LeftColumn>
				{isDesktop && !isNetlifyWidget && <BlogUpdates />}
			</PostContent>
			{!isNetlifyWidget && (
				<PreviousArticles>
					<BlogPostPreviewList
						title={previous.title}
						posts={posts.filter(e => e.title !== title).slice(0, 3)}
					/>
				</PreviousArticles>
			)}
			{(isTablet || isPhone) && !isNetlifyWidget && <BlogUpdates />}
		</Container>
	);
}
