import styled from 'styled-components';

import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import { Colors, MediaQueries, Sizes } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin: 0;
	padding: 0;

	@media ${MediaQueries.phone} {
		padding: 0 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding: 0 2rem;
	}
`;

export const PostContent = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;

export const PreviousArticles = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 12rem 0;

	@media ${MediaQueries.phone} {
		margin: 6rem 0;
	}

	@media ${MediaQueries.desktop} {
		h2 {
			margin-bottom: 8rem;
		}
	}
`;

export const LeftColumn = styled.div`
	overflow: auto;
`;

export const RightColumn = styled.div`
	margin-left: 12rem;
`;

export const MarkdownContent = styled.div`
	font-family: 'Open Sans';
	font-size: 1.8rem;
	line-height: 2.7rem;
	white-space: pre-line;

	h1,
	h2,
	h3 {
		font-size: 3.6rem;
		line-height: 5rem;
		margin-top: 2rem;

		&:first-child {
			margin-top: 0;
		}
	}

	a {
		border-bottom: 0.2rem transparent solid;
		transition: border-color 0.2s;

		&:hover {
			border-color: ${Colors.green};
		}
	}

	img {
		max-width: 100%;
		max-height: 100%;
	}

	ol,
	ul {
		display: flex;
		flex-direction: column;

		& > li:not(:last-child) {
			margin-bottom: 1rem;
		}
	}

	table {
		width: 100%;

		& > tbody {
			text-align: center;
		}
	}
`;
